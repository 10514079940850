<template>
   <div class="paper">
      <van-nav-bar title="创建用户" />

      <div class="form">
          
         <div class="form-one">
             <van-field class="field" v-model="phone" placeholder="手机号" type="tel" />
            <van-field class="field" v-model="phoneCode" clearable placeholder="验证码">
                <template #button>
                    <van-button id="btn" round type="primary" >发送验证码</van-button>
                </template>
            </van-field>
            <van-field class="field" v-model="kname" placeholder="请输入孩子真实姓名" input-align="center"
               :rules="[{ required: true, message: '请输入孩子真实姓名' }]" />
            <van-field class="field" v-model="sexName" placeholder="请选择孩子性别" input-align="center" @click="showSex = true" readonly
               :rules="[{required: true, message: '请选择孩子性别' }]" />
            <van-field class="field" v-model="birthday" placeholder="请选择出生年月" input-align="center" @click="showDate = true" readonly
               :rules="[{required: true, message: '请选择出生年月' }]" />
            <van-field class="field" v-model="card" placeholder="请输入孩子身份证号" input-align="center" @blur="cardInput"
               :rules="[{message: '请输入孩子身份证号' }]" />
         </div>
         
         <van-popup v-model="showSex" position="bottom">
            <van-picker title="请选择性别" show-toolbar :columns="sexs" value-key="v" @confirm="sexConfirm"
               @cancel="showSex = false" />
         </van-popup>
         <van-popup v-model="showDate" position="bottom">
            <van-datetime-picker v-model="birthdays" type="date" title="选择年月日" :min-date="minDate"
               @cancel="showDate = false" :max-date="maxDate" @confirm="dateConfirm" />
         </van-popup>

      </div>
      <div class="btn">
         <van-button round type="primary" @click="submit">确认</van-button>
      </div>

   </div>
</template>
<script>
   import {
      mapGetters
   } from "vuex";
   import dayjs from "dayjs";
   export default {
      data() {
         return {
            sexs: [{"v":"男","k":"1"},{"v":"女","k":"2"}],
            showLeftArrow: true,
            kname: "", //宝宝姓名
            card: '', //身份证
            sex: '',
            sexName: '',
            birthday: '',
            mapPopup: false,
            address: '',
            province: '', // 省
            city: '', // 市
            area: '', // 区
            lng: '', // 经度
            lat: '', // 纬度
            school: '',
            classes: '',
            scid: '',
            cid: '',
            num: '',
            schoolList: [],
            classList: [],
            showSex: false,
            showDate: false,
            minDate: new Date(2000, 0, 1),
            maxDate: new Date(),
            showSchool: false,
            showClasses: false,
            showArea: false,
            add1: '',
            add2: '',
            add3: '',
            birthdays: '',
            btnLoading: false,
            showInfo: false, // 筛查信息弹出层
            eyesInfo: {
            },
            kid: '', // 孩子ID
            isloading: false, // 获取验证码按钮加载状态
            loadingText: "",
            phone: "",
            phoneCode: "",
         };
      },
      mounted() {
         
      },
      methods: {
        getCode() {
            if (!this.checkPhone()) return;
            this.isloading = true;
            this.loadingText = `重新获取(${this.num}s)`;
            var timer = setInterval(() => {
               this.loadingText = `重新获取(${--this.num}s)`;
               if (this.num == 0) {
                  this.isloading = false;
                  this.num = 60;
                  clearInterval(timer);
               }
            }, 1000);
            this.$api.getCode({ phone: this.phone }).then(res => {
               this.$toast.success(res.msg);
            });
         },
         onClickLeft() {
            this.$router.replace({
               path: sessionStorage.getItem("child_back_path")
            })
         },
         sexConfirm(val) {
            this.sex = val.k
            this.sexName = val.v
            this.showSex = false
         },
         dateConfirm(val) {
            let str = dayjs(val).format('YYYY/MM/DD')
            this.birthday = str
            this.showDate = false;
         },
         cardInput() {
            let reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            if (!reg.test(this.card)) {
               this.$toast.fail("请输入正确的18位身份证号")
            } else {
               let tmpStr = this.card.substring(6, 14);
               this.birthday = tmpStr.substring(0, 4) + '/' + tmpStr.substring(4, 6) + '/' + tmpStr.substring(6);
               this.sexName = parseInt(this.card.substring(17, 1), 10) % 2 ? "男" : "女"
               this.sex = parseInt(this.card.substring(17, 1), 10) % 2 ? 1 : 2
            }
         },
         
         checkForm() {
            if (this.kname == '' || this.sex == "" || this.birthday == "") {
               this.$toast.fail('请填写基本信息')
               return false
            } else {
               return true
            }
         },
         submit() {
            if (!this.checkForm() || this.btnLoading) return
            this.btnLoading = true
            let sex = this.sexName == '男' ? 1 : 2;
            // 判断是否填写宝宝姓名 出生年月 性别
            let birthdata = dayjs(this.birthday).valueOf();
            let data = {
               kname: this.kname,
               birthdata,
               sex: this.sex,
               card: this.card,
               add1: this.province,
               add2: this.city,
               add3: this.area,
               lng: this.lng,
               lat: this.lat,
               scid: this.scid,
               cid: this.cid,
               num: this.num,
            };
            this.$api.child_add(data).then(res => {
               if (res.sta == 0) {
                  this.$router.push({
                     path: "/record",
                     query: {
                        kid: res.id,
                        can: true
                     }
                  });
               } else if (res.sta == 1) {
                  this.eyesInfo = res.info;
                  this.kid = res.id
                  this.showInfo = true;
               } else {
                  this.btnLoading = false
               }
               this.get_child_list();
            });
         },
      },
      computed: {
         
      },
   }
</script>
<style lang="less" scoped>
   .paper {
      min-height: 100%;
      background: #f8f9fa;
   }
  
   .form-one {
      background: #fff;
      padding: 5vw;

      .van-cell {
         margin-bottom: 10px;
      }
   }

   .form-tip {
      font-size: 3.33vw;
      color: #ed6a0c;
      padding: 3vw;
      margin: 10px 0;
      text-align: center;
   }

   .form-title {
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 3.7vw;
      font-weight: bold;
      margin-bottom: 10px;
   }


   .btn {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      button {
         width: 44vw;
         height: 11vw;
         border-radius: 6vw;
         font-size: 4vw;
         color: #fff;
      }
   }

   .van-cell::after {
      border-bottom: 0px solid #ebedf0;
   }

   .field {
      width: 100%;
      height: 13vw;
      background-color: #f8f9fa;
      border-radius: 1.62vw;
   }

   #btn {
        width: 28vw;
        height: 8vw;
    }

   .map-popup {
      height: 100%;
      width: 100%;
   }

   .map-btn {
      height: 50px;
      text-align: center;

      button {
         width: 44vw;
         height: 40px;
         border-radius: 6vw;
         font-size: 4vw;
         color: #fff;
      }
   }

   .inputs>div {
      padding: 10px 0;
   }

   #mapPage {
      width: 100%;
      height: calc(100% - 80px);
   }

   .info-dialog /deep/.van-dialog__header {
      font-size: 18px;
      height: 66px;
      font-weight: normal;
      color: #191919;
   }

   .info-dialog /deep/.van-dialog__content {
      padding: 5px 20px 20px;
      color: #333;
   }

   .info-dialog .van-dialog__content .van-row {
      height: 35px;
      line-height: 35px;
   }

   .info-dialog .van-col--10 {
      text-align: right;
   }

   .info-dialog .van-col--7 {
      text-align: center;
   }

   /deep/.van-dialog__footer button {
      color: #333;
   }

</style>